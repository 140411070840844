
$primary: #1F3054;
$secondary: #F16E50;
$Dark: #111434 ;
$my-purple: #440B7D ;

$my-colors: (
"my-purple": $my-purple  
);

$border-radius-lg:            .6rem;

$form-select-indicator: url(../src/assets/arrow-dropdown.svg);
$form-select-indicator-color: white;

.nav-logo{
    height: 50px;
    width: auto;
}
.logo{
    height: 190px;
    width: auto;
}


/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.wave-1{
    background-image: url(../src/assets/small_cut.svg);
    background-repeat: no-repeat;
    background-position: 80% 350%;
    @media (max-width: 768px) {
        background-position: 80% 150%; 
    }
}

.wave-2{
  position: absolute;
 width: 100%;
 overflow: hidden;
  bottom: -10%;

  z-index: -1000;
}

.text-area{
  resize: none;
}


.wave{
  position: relative;
  background-image: url(../src/assets/big_cut.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100px;
}
