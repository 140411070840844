
@import "mystyle.scss";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";


$theme-colors: map-merge($theme-colors, $my-colors);
@import "../node_modules/bootstrap/scss/bootstrap.scss";
    

